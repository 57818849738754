.qrcode[data-v-5e2240ee] {
  width: 100%;
  text-align: center;
}
[data-v-5e2240ee] .el-tabs__nav-scroll {
  width: 100% !important;
}
.el-container[data-v-5e2240ee] {
  height: 100%;
}
.area_tabs[data-v-5e2240ee] {
  height: calc(100% - 300px);
}
.el-tabs .el-tabs--top[data-v-5e2240ee] {
  height: 100%;
}
[data-v-5e2240ee] .el-tabs__content {
  height: calc(100% - 70px) !important;
}
[data-v-5e2240ee] .el-tabs__nav-scroll {
  padding-left: 12px !important;
}
.areaWorkRecord[data-v-5c805e6c] {
  height: 100%;
}
.areaWorkRecord .el-main[data-v-5c805e6c] {
  overflow: hidden;
}
[data-v-5c805e6c] #gridLayout {
  height: calc(100% - 100px) !important;
}
[data-v-5c805e6c] .avue-crud {
  height: 100% !important;
}
[data-v-5c805e6c] .avue-crud .el-card {
  height: 100% !important;
  overflow: hidden;
}
[data-v-5c805e6c] .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-5c805e6c] .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-5c805e6c] .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
  max-height: 100% !important;
}
.parent_height[data-v-6673ddd0] {
  height: 100%;
}
[data-v-6673ddd0] .itemListGrid .avue-crud .el-table {
  height: calc(100vh - 340px) !important;
  max-height: calc(100vh - 340px) !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-6673ddd0] .el-upload {
  text-align: left;
}
.upload-text[data-v-6673ddd0] {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
[data-v-6673ddd0] .commonTreeCore .el-tree {
  height: calc(100% - 120px) !important;
}
.areaCheckList[data-v-7712e5e8] {
  height: 100%;
}
.areaCheckList .el-main[data-v-7712e5e8] {
  overflow: hidden;
}
[data-v-7712e5e8] .tabGridLayOut {
  height: calc(100% - 100px) !important;
}
[data-v-7712e5e8] .avue-crud {
  height: 100% !important;
}
[data-v-7712e5e8] .avue-crud .el-card {
  height: 100% !important;
  overflow: hidden;
}
[data-v-7712e5e8] .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-7712e5e8] .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-7712e5e8] .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
  max-height: 100% !important;
}
[data-v-7712e5e8] .areaCheckListTree .el-tree {
  height: calc(100% - 120px) !important;
}